//----------------------------------------
// Import
//----------------------------------------

import * as mdc from 'material-components-web';
/*import { mdcAutoInit } from '@material/auto-init';
import { MDCBanner } from '@material/banner';
import { MDCComponent, MDCFoundation } from '@material/base';
import { MDCCheckbox } from '@material/checkbox';
import { MDCChip } from '@material/chips';
import { MDCCircularProgress } from '@material/circular-progress';
import { MDCDataTable } from '@material/data-table';
import { MDCDialog } from '@material/dialog';
import * as ponyfill from '@material/dom/ponyfill';
import { MDCDrawer } from '@material/drawer';
import { MDCFloatingLabel } from '@material/floating-label';
import { MDCFormField } from '@material/form-field';
import { MDCLineRipple } from '@material/line-ripple';
import { MDCLinearProgress } from '@material/linear-progress';
import { MDCList } from '@material/list';
import { MDCMenuSurface } from '@material/menu-surface';
import { MDCMenu } from '@material/menu';
import { MDCNotchedOutline } from '@material/notched-outline';
import { MDCRadio } from '@material/radio';
import { MDCRipple } from '@material/ripple';
import { MDCSegmentedButton } from '@material/segmented-button';
import { MDCSelect } from '@material/select';
import { MDCSlider } from '@material/slider';
import { MDCSnackbar } from '@material/snackbar';
import { MDCSwitch } from '@material/switch';
import { MDCTabBar } from '@material/tab-bar';
import { MDCTabIndicator } from '@material/tab-indicator';
import { MDCTabScroller } from '@material/tab-scroller';
import { MDCTab } from '@material/tab';
import { MDCTextField } from '@material/textfield';
import { MDCTooltip } from '@material/tooltip';
import { MDCTopAppBar } from '@material/top-app-bar';*/

import './assets/scss/app.scss';
import 'cookieconsent';

//----------------------------------------
// Initialisation
//----------------------------------------

mdc.autoInit();
//mdcAutoInit();

// Drawer
//----------------------------------------

const drawer = mdc.drawer.MDCDrawer.attachTo(document.querySelector('.mdc-drawer')!);
//const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer')!);

const listEl = document.querySelector('.mdc-drawer .mdc-deprecated-list')!;
const mainContentEl = document.querySelector('.main-content')!;
//const mainContentEl = document.getElementById('main-content')!;

listEl.addEventListener('click', (event) => {
  drawer.open = false;
});

document.body.addEventListener('MDCDrawer:closed', () => {
  //mainContentEl.querySelector('input, button')!.focus();
  const focusTrap = document.getElementById('zendeskLauncher')! as HTMLElement;
  //const focusTrap = mainContentEl.querySelector('.focus-trap')![0] as HTMLElement;
  focusTrap.focus();
});

// Top app bar
//--------------------

const topAppBar = new mdc.topAppBar.MDCTopAppBar(document.querySelector('.mdc-top-app-bar')!);

topAppBar.setScrollTarget(document.getElementById('main-content')!);
topAppBar.listen('MDCTopAppBar:nav', () => {
  drawer.open = !drawer.open;
});

/*const topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar')!);
topAppBar.setScrollTarget(document.getElementById('main-content')!);
topAppBar.listen('MDCTopAppBar:nav', () => {
  drawer.open = !drawer.open;
});*/

// Tab bar
//----------------------------------------

//const tabBar = new MDCTabBar(document.querySelector('.mdc-tab-bar')!);
const tabBar = new mdc.tabBar.MDCTabBar(document.querySelector('.mdc-tab-bar')!);

// Text field helper text
//--------------------

/*const helperTextEls = Array.from(
  document.querySelectorAll('.mdc-text-field-helper-text')
);
helperTextEls.forEach((helperTextEl) => {
  const helperText = new mdc.textField.MDCTextFieldHelperText(
    helperTextEl
  );
});*/

// Button
//--------------------

const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action';
const ripples = [].map.call(document.querySelectorAll(selector), function (el) {
  return new mdc.ripple.MDCRipple(el);
  //return new MDCRipple(el);
});

// Menu
//--------------------

const menuEls = Array.from(document.querySelectorAll('.mdc-menu'));
menuEls.forEach((menuEl) => {
  const menu = new mdc.menu.MDCMenu(menuEl);
  //const menu = new MDCMenu(menuEl);
  const dropdownToggle = menuEl.parentElement!.querySelector('#avatarButton');
  dropdownToggle!.addEventListener('click', () => {
    menu.open = !menu.open;
  });
});

// List
//--------------------

const list = new mdc.list.MDCList(document.querySelector('.mdc-deprecated-list')!);
//const list = new MDCList(document.querySelector('.mdc-deprecated-list')!);

// Table
//--------------------

//const dataTable = new MDCDataTable(document.querySelector('.mdc-data-table')!);
const dataTable = document.querySelector('.mdc-data-table')!;

if (typeof dataTable != 'undefined' && dataTable != null) {
  const dataTable2 = new mdc.dataTable.MDCDataTable(document.querySelector('.mdc-data-table')!);
}

// Cookie consent
//--------------------

declare global {
  interface Window {
    cookieconsent: any;
  }
}
window.cookieconsent = window.cookieconsent || {};

window.cookieconsent.initialise({
  palette: {
    popup: {
      background: 'rgba(68, 68, 68, .9)',
      text: '#ffffff',
    },
    button: {
      background: '#ffffff',
      text: '#333333',
    },
  },
  window:
    '<div role="dialog" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window {{classes}}"><div class="container">{{children}}</div></div>',
  content: {
    message:
      'We use cookies. By continuing to use this website you are giving your consent for us to ',
    dismiss: 'Dismiss',
    link: 'set cookies',
    href: 'https://www.cambridgeenglish.org/footer/data-protection/cookie-policy/',
  },
});

// Year

 const containerEl = document.getElementById("year")!;
 const yearValue = new Date().getFullYear();
 containerEl.innerHTML = yearValue.toString();
